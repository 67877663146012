type Config = {
  regen721Contract: string
}

const configs: Config = {
  regen721Contract: process.env
    .NEXT_PUBLIC_REGEN_721_CONTRACT_ADDRESS as string,
}

export default configs
