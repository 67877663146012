export enum QUERY_KEY {
  GET_NFT_COLLECTIONS = 'QUERY_KEY:GET_NFT_COLLECTIONS',

  // Regen
  GET_SEASON = 'QUERY_KEY:GET_SEASON',
  GET_SEASONS = 'QUERY_KEY:GET_SEASONS',
  GET_SEASON_SETTING = 'QUERY_KEY:GET_SEASON_SETTING',
  GET_SEASON_METADATA = 'QUERY_KEY:GET_SEASON_METADATA',
  GET_SEASON_ROUND = 'QUERY_KEY:GET_SEASON_ROUND',
  GET_SEASON_ROUNDS = 'QUERY_KEY:GET_SEASON_ROUNDS',
  GET_ROUND_WHITELIST = 'QUERY_KEY:GET_ROUND_WHITELIST',
  GET_ROUNDS_WHITELIST = 'QUERY_KEY:GET_ROUNDS_WHITELIST',
  GET_TOTAL_BURNED_BY_USER_ADDRESS = 'QUERY_KEY:GET_TOTAL_BURNED_BY_USER_ADDRESS',
  GET_HAS_OPERATOR_ROLE = 'QUERY_KEY:GET_HAS_OPERATOR_ROLE',
  GET_NEXT_SEASON_ID = 'QUERY_KEY:GET_NEXT_SEASON_ID',

  // Elite
  GET_UNREVEALED_TOKEN_IDS = 'QUERY_KEY:GET_UNREVEALED_TOKEN_IDS',

  GET_NEXT_TOKEN_ID = 'QUERY_KEY:GET_NEXT_TOKEN_ID',
  GET_TOTAL_SUPPLY = 'QUERY_KEY:GET_TOTAL_SUPPLY',
  GET_TOKEN_IDS_OWNED_BY_ADDRESS = 'QUERY_KEY:GET_TOKEN_IDS_OWNED_BY_ADDRESS',
  GET_IS_ERC721_APPROVE_FOR_ALL = 'QUERY_KEY:GET_IS_ERC721_APPROVE_FOR_ALL',
  GET_TOKEN_URI = 'QUERY_KEY:GET_TOKEN_URI',
  GET_BALANCE_OF_ADDRESS = 'QUERY_KEY:GET_BALANCE_OF_ADDRESS',
  GET_IS_BURNABLE_CONTRACT = 'QUERY_KEY:GET_IS_BURNABLE_CONTRACT',
  GET_IS_MINTABLE_CONTRACT = 'QUERY_KEY:GET_IS_MINTABLE_CONTRACT',

  // Nft service
  GET_NFT_INFO = 'QUERY_KEY:GET_NFT_INFO',
  GET_ENCRYPTION_KEY = 'QUERY_KEY:GET_ENCRYPTION_KEY',
}

export enum MUTATION_KEY {
  APPROVE_721_FOR_ALL = 'MUTATION_KEY:APPROVE_721_FOR_ALL',

  BURN_NFTS = 'MUTATION_KEY:BURN_NFTS',
  CREATE_SEASON = 'MUTATION_KEY:CREATE_SEASON',
  UPDATE_SEASON = 'MUTATION_KEY:UPDATE_SEASON',

  REVEAL_METADATA = 'MUTATION_KEY:REVEAL_METADATA',

  UPLOAD_SEASON_METADATA = 'MUTATION_KEY:UPLOAD_SEASON_METADATA',
}

export enum LOCAL_STORAGE_KEY {
  S3_CREDENTIAL = 'LOCAL_STORAGE_KEY:S3-CREDENTIAL',
}
